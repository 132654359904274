<template>
	<ion-page>
		<Header currentPage="homepage"/>
		<ion-content>
			<div class="top-section">
				<div class="container">
					<div class="text-wrapper">
						<h1>OpenAcademy</h1>
						<p>
							<b>Vitajte v OpenAcademy!</b><br />Svet technológií sa neustále
							vyvíja. Aby sme dokázali mladé IT talenty pripraviť na
							technologické výzvy 21. storočia, potrebujeme študentom prinášať
							aktuálne témy. Vzdelávaciu platformu OpenAcademy využijú študenti
							aj učitelia. Zapojte sa aj Vy a naučte sa spolu s Vašimi študentmi
							vyvíjať aplikácie, hry a IoT zariadenia. Materiály pre výuku
							pripravujeme s odborníkmi z technologických firiem v danom
							segmente.
						</p>
					</div>
					<img src="@/assets/images/intro_photo.png" alt="illustration-image" />
				</div>
			</div>
			<div class="courses-section">
				<div class='container'>
					<div class="d-flex justify-content-between title">
						<h1>Témy</h1>
						<span class="link" @click="$router.push({name: 'home'})">Prejsť na všetky témy ></span>    
					</div>

					<w-loadable class="container" :items="themes" skelet="none" messageEmpty="Aktuáľnemu hľadaniu nevyhovujú žiadné témy">
						<!-- <ion-row class="themes">
							<ion-col v-for="theme in themes.slice(0, 3)" :key="theme.id"  size="12" size-sm="6" size-md="4">
								<ion-card @click="$router.push({name: 'theme', params: {themeSlug: theme.slug}})">
									<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
										<img v-if="theme.image?.path" :src="theme.image?.path">
										<img v-else src="./img/placeholder-img.jpg">
									</ion-card-header>

									<hr>

									<ion-card-title>{{ theme.name }}</ion-card-title>
									<ion-chip color="primary" v-for="tag in (theme.tags ?? [] )" :key="tag">{{ tag }}</ion-chip>
									<ion-text color="primary" class="bottom-button">Začať</ion-text>
								</ion-card>
							</ion-col>
						</ion-row> -->
						<ion-row>
							<ion-col v-for="theme in themes" :key="theme.id"  size="12" size-sm="6" size-md="4">
								<ion-card @click="$router.push({name: 'theme', params: {themeSlug: theme.slug}})">
									<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
										<img v-if="theme.image?.path" :src="theme.image?.path">
										<img v-else src="./img/placeholder-img.jpg">
									</ion-card-header>

									<hr>

									<ion-card-title>{{ theme.name }}</ion-card-title>
									<ion-chip color="primary" v-for="tag in (theme.tags ?? [] )" :key="tag">{{ tag }}</ion-chip>
									<ion-text color="primary" class="bottom-button">Začať</ion-text>
								</ion-card>
							</ion-col>	
						</ion-row>
						<h2 class="themes-link" @click="$router.push({name: 'home'})">Prejsť na všetky témy ></h2>
					</w-loadable>
				</div>
			</div>
			<div class="testimonials-section">
				<div class="container">
					<h1 style="padding-top: 20px;">Povedali o nás</h1>
					<div class="testimonials-cards-wrapper d-flex-cards-wrapper">
						<testimonial-card
							v-for="testimonial in testimonials"
							:key="testimonial.id"
							:testimonial="testimonial"
							fontcolor="rgba(255, 255, 255, 0.9)"
						></testimonial-card>
					</div>
				</div>
			</div>
			<Footer />
		</ion-content>
	</ion-page>
</template>

<script>

import testimonialCard from './components/TestimonialCard.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import testimonials from './assets/testimonials.json'

import wAxios from '@/plugins/w/axios'

const api = {
	read: () => wAxios.get_auth_data(`v1/themes`),
}


export default {
	components: {testimonialCard, Footer, Header},
	data() {
		return {
			themes: null,
			testimonials: testimonials
		}
	},
	async created() {
		this.themes = await api.read()

	}
}
</script>

<style lang="scss" scoped>
.top-section {
  background-color: #471dc1;
	padding: 0px 30px 0px 30px;

  position: relative;

  .container {
    padding: 90px 0 110px 0;

    .text-wrapper {
      max-width: 475px;

      * {
        color: #ffffff;
      }

      p {
        line-height: 28px;
        font-size: 14px;
        font-weight: 100;
      }
    }

    img {
      height: 494px;

      position: absolute;
      bottom: -68px;
      right: 58px;
    }

    @media only screen and (max-width: 1350px) {
      img {
        height: 350px !important;
      }
    }

    @media only screen and (max-width: 1050px) {
		text-align: center;

      img {
        display: none !important;
      }

      .text-wrapper {
        max-width: 100% !important;
      }
    }
  }
}

.courses-section {
    padding-top: 30px;
  .container {
    min-height: 500px;
    width: 100%;
  }
  h1 {
    color: #494949;
  }

  .fake-card {
    width: 354px;
  }
}

.testimonials-section {

  .container {
    min-height: 500px;
  }
  background-color: #289ffd;

  * {
    color: #ffffff;
  }

  .testimonials-cards-wrapper {
    padding-bottom: 30px;
  }
  @media only screen and (max-width: 1050px) {
		text-align: center;
	}
}
//ble
h1 {
    line-height: 55px;
    font-size: 45px;
    font-weight: 900 !important;
    margin-bottom: 20px;
}
.d-flex-cards-wrapper {
  padding-top: 30px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 1050px) {
		justify-content: center;
		align-items: center;
	}
}

.links {
    width: 300px;
    display: flex;
    justify-content: space-around;
    font-weight: 500;
    margin-right: 17px;
    
    * {
        cursor: pointer;
    }

    .active {
        border-bottom: 3px solid #4632c1;
        color: #4632c1;
    }

    @media only screen and (max-width:860px) {
        width: 150px;
        font-size: 12px;
    }
}
.buttons-wrapper {


	padding: 0;
	max-width: 650px;
	margin: auto;

	ion-button {
        opacity: 0.6;
		margin: 0;
		border-radius: 3px;
		box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, .4);
		height: 35px;
    }
		
}
ion-row {
    margin-top: 5px;
    padding-bottom: 20px;
	
}
	
ion-col {
    --ion-grid-column-padding: 10px;
	ion-card {
        padding-bottom: 45px;
		height: 500px;
    }
		
		img {
			height: 160px;
        }

		ion-card-title {
			margin-bottom: 5px;
			font-weight: 500;
			color: var(--ion-color-dark);
        }
			
}
.bottom-button {
    font-weight: 500;
    font-size: 16px;
}

.link{
    font-size: 18px;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 50px;
}
.link:hover {
    color: #4632c1;
}
.logo {
    font-size: 30px;
    font-weight: 600;
    display: none;
}
.brand-wrapper {
    @media only screen and (max-width:510px) {
        display: none;
    }
		display: flex;
		align-items: center;
        padding: 20px;

		* {
			margin-right: 20px;
		}

		img {
			height: 40px;
            @media only screen and (max-width:630px) {
                height: 20px;
            }
		}

		img:hover {
			cursor: pointer;
		}

		p {
			margin-bottom: 0;
			font-size: 13px;
			font-weight: 100;
			color: #505050;
		}

		.vertical-line {
			width: 1px;
			height: 45px;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
	.title {
		@media only screen and (max-width: 1050px) {
			display: block !important;
			text-align: center;
			
		}
	}
.themes-link {
	text-align: center;
	text-decoration: underline;
	font-weight: bold;
	cursor: pointer;
}
//themes
.buttons-wrapper {
  padding: 0;
  max-width: 650px;
  margin: auto;

  ion-button {
    opacity: 0.6;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, .4);
    height: 35px;
    &.active {
      opacity: 1;
    }
  }
}
ion-row {
  margin-top: 5px;
  max-width: 900px;
  margin: auto;
}
ion-col {
  --ion-grid-column-padding: 5px;

  ion-card {
    padding-bottom: 45px;
    height: 100%;

    img {
      height: 80px;
    }
    ion-card-title {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 400;
      color: var(ion-color-dark);
    }
  }
}
</style>
<template>
	<div class="testimonials-card">
		<div class="image-wrapper">
			<img :src='testimonial.image ? testimonial.image.path : ""' alt="avatar">
		</div>
		<h4 :style="'color: ' + fontcolor">{{ testimonial.name }}</h4>
		<h6 :style="'color: ' + fontcolor">{{ testimonial.title }}</h6>
		<p :style="'color: ' + fontcolor + ' !important'" v-html="testimonial.short_description"></p>
	</div>
</template>

<script>
export default {
	props: {
		testimonial: {
			type: Object,
			required: true,
		},
		fontcolor: {
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.testimonials-card {
		width: 322px;
		margin-bottom: 75px;

		.image-wrapper {
			height: 190px;
			width: 190px;

			overflow: hidden;
			margin-bottom: 30px;

			border-radius: 115px;
			border: 2px solid #ffffff;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			@media only screen and (max-width: 1050px) {
				margin-left: 70px;
			}
		}

		h4 {
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 5px;
		}

		h6 {
			font-size: 15px;
			font-weight: 100;
			margin-bottom: 20px;
		}

		p {
			font-size: 16px;
			font-weight: 100;
			line-height: 26px;
			color: #ffffff !important;
		}

		a {
			font-size: 18px;
			font-weight: 100;
			text-decoration: underline;
		}
	}
</style>

<style scoped>
	.testimonials-card >>> p {
		color: #ffffff;
	}
</style>